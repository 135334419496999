import "./search.css"

import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
// @ts-ignore
import { useFlexSearch } from "react-use-flexsearch"

import Layout from "@/templates/layout"
import { useQueryParam, StringParam } from "use-query-params"
import { Helmet } from "react-helmet"


const Search = (props: any) => {
  const [search] = useQueryParam("q", StringParam)
  const { localSearchPages: { index, store } } = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `)

  const results = useFlexSearch(search, index, store)

  return (
    <Layout {...props} title="Search Results">
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      {results.length > 0 && <>
        <h1>Search results for {search}:</h1>
        <ol className="search-results">
          {results.map((post: any, i: number) => 
          <li key={i} className="search-result">
            <Link to={post.url} target="_blank" itemProp="url">
                <small className="button">{post.title}</small>
            </Link>
          </li>)}
        </ol>
      </>}
      {results.length === 0 && <>
        <h1>No results for {search}</h1>
        <Link to="/" className="button">Go Back</Link>
      </>}
    </Layout>
  )
}

export default Search